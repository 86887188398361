<template>
  <div class="app-container">
    <!-- 顶部按钮 -->
    <div class="top_btns clearfix">
      <div class="left_search fl">
        <el-button class="filter-item" type="success" size="small" @click="goback()" plain>
          <el-icon><Back /></el-icon><span  > 返回</span>
        </el-button>
        <!-- <el-input placeholder="请输入姓名查询" v-model="cnName" style="width: 200px;" class="filter-item" size="small" />
        <el-button class="filter-item" @click = chaxunname() type="primary" size="small" plain>{{ $t('table.search') }}</el-button> -->
      </div>
      <div class="right_btns fr">
        <qzf-button type="primary" size="small" @success="addPersonal">
          <el-icon><CirclePlus /></el-icon><span  > 添加</span>
        </qzf-button>
        <qzf-button type="primary" size="small" @success="saveAll" v-loading.fullscreen.lock="fullscreenLoading">
          <i class="iconfont icon-baocun" style="margin-right:4px;font-size: 14px;"></i>
          <span  > 保存</span>
        </qzf-button>
        <el-dropdown style="margin-left: 6px;" size="small">
          <qzf-button jz="false" button_code="xc_gzb_tj" type="primary" size="small">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </qzf-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="a" @click="printingA4()">
                <span>打印A4</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" @click="printingA5()">
                <span>打印凭证纸</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" @click="GetInportListClick()">
                <span>导出</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <!-- 顶部按钮 end -->
    <!-- 上方信息 -->
    <div class="com_info">
      <div class="top_info">
        <div class="top_item">
          <p>企业类型：<span v-if="info.comType == ''">空</span><span v-else>{{info.comType}}</span></p>
          <p>征收方式：<span>{{info.wageType}}-核定应税所得率征收(能准确核算收入总额的)</span></p>
        </div>
      </div>
      <div class="bottom_info">
        <div class="info_list">
          <p>利润信息：</p>
          <span>
            收入总额：<el-input type="number" v-model.number="info.srAmount" :precision="2" style="width: 120px;" size="small" @change="saveValue" />
            <qzf-button  type="primary" size="small" plain @success="getValue"   >
              <el-icon><Coin /></el-icon><span   style="margin:0"> 自动取值</span>
            </qzf-button>
          </span>
        
        </div>
        <div class="bottom_info">
          <div class="info_list">
            <div class="wxts">温馨提示：若以下人员含有正常工资薪金并已享受减除费用，那么重复享受减除费用后，减除费用超出6万元则年度汇算清缴时将会产生补税，按实际情况可将投资者减除费用修改为0。</div>
          </div>
        </div>

      </div>
    </div>
    <!-- 上方信息 end -->
    <!-- 内容 -->
    <div class="content">
      <el-table :height="contentStyleObj" show-summary :header-cell-style="{background: '#eee',color:'#333','font-size': '13px'}" @selection-change="handleSelectionChange" v-loading="listLoading" :data="listData" border fit highlight-current-row style="width: 100%;">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column align="center" type="selection" width="55"> </el-table-column>
        <el-table-column fixed label="序号" type="index" align="center" width="55"></el-table-column>
        <el-table-column fixed label="姓名" align="center" min-width="200">
          <template #default="{row}">
            <span>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="分配比例%" align="center" min-width="150">
          <template #default="{row}">
            <span>
              {{row.stockPercent}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="投资者减除费用" align="center" min-width="150" prop="costReduction">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.costReduction"></span>
          </template>
        </el-table-column>
        <el-table-column label="准予扣除的捐赠额" align="center" min-width="150" class-name="action_btns" prop="jzr">
          <template #default="{row}">
            <span>
              {{row.jzr}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="基本养老保险" align="center" min-width="130" prop="deductSbPension">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.deductSbPension"></span>
          </template>
        </el-table-column>
        <el-table-column label="基本医疗保险" align="center" min-width="130" prop="deductSbMedical">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.deductSbMedical"></span>
          </template>
        </el-table-column>
        <el-table-column label="失业保险" align="center" min-width="130" prop="deductSbUnemployment">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.deductSbUnemployment"></span>
          </template>
        </el-table-column>
        
        <el-table-column label="住房公积金" align="center" min-width="130" prop="deductHouseFund">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.deductHouseFund"></span>
          </template>
        </el-table-column>
        <el-table-column label="商业健康保险" prop="syx" align="center" min-width="130" >
          <template #default="{row}">
            <span><input type="text" v-model.number="row.syx"></span>
          </template>
        </el-table-column>
        <el-table-column label="其他扣除" align="center" min-width="130" prop="deductOther">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.deductOther"></span>
          </template>
        </el-table-column>
        <el-table-column label="已缴纳税额" align="center" min-width="150" prop="yjnse">
          <template #default="{row}">
            <span><input type="text" v-model.number="row.yjnse" @change="computedYbtse()"></span>
          </template>
        </el-table-column>
        <el-table-column label="减免税额" align="center" min-width="150" class-name="action_btns" prop="jmse">
          <template #default="{row}">
            <span>
              <input type="text" v-model.number="row.jmse" disabled style="width:70%">
              <i class="iconfont icon-bianji" @click="jmseDialog(row)"></i>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="应补（退）税额" align="center" min-width="150" prop="ybtse">
          <template #default="{row}">
            <span>{{ row.ybtse }}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="操作" align="center" min-width="120" class-name="small-padding fixed-width top_right_btns">
          <template #default="{row}">
            <el-tooltip content="删除" placement="top" effect="dark">
              <i class="iconfont icon-shanchu" @click="del(row)"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 内容 end -->
  </div>

  <!-- 减免税额弹框 -->
  <el-dialog :close-on-click-modal="false" destroy-on-close v-model="dialogFormVisible2" title="减免税额" width="80%">
      <el-button type="primary" @click="handleAdd" plain class="addBtn" size="small">新增</el-button>
      <el-table  :data="jmseList" border fit>
        <el-table-column label="id" prop="id" align="center" width="55" type="index"></el-table-column>
        <el-table-column label="减免税额" align="center" width="120" prop="jmse">
          <template #default="{row}">
             <input type="text" v-model.number="row.jmAmount" style="width:80%" size="small">
          </template>
        </el-table-column>
        
        <el-table-column label="*所得项目" align="center" width="150" prop="sdxm">
          <template #default="{row}">
            <span>
              <el-select v-model="row.sdxm" placeholder="请选择" size="small">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="*减免事项" align="center" min-width="100" prop="jmsx">
          <template #default="{row}">
              <el-select @change="changeJmsx(row)" v-model="row.jmsx" placeholder="" size="small" style="width: 100%;">
                <el-option
                  v-for="item in options"
                  :key="item.label"
                  :label="item.label"
                  :value="item.label">
                </el-option>
              </el-select>
          </template>
        </el-table-column>
        <el-table-column label="*减免性质" align="center" min-width="100" :show-overflow-tooltip="true" style="overflow:hidden" prop="jmxz">
          <template #default="{row}">
            <span style="display:block;overflow:hidden;text-overflow:ellipsis">
                {{row.jmxz}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80">
           <template #default="scope">
            <el-tooltip content="删除" placement="top" effect="dark">
              <i @click="deleteRow(scope.$index, jmseList)" class="iconfont icon-shanchu"></i>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible2 = false" size="small">取消</el-button>
          <el-button type="primary" @click="savejmse()" size="small">确认</el-button>
        </span>
      </template>
    </el-dialog>
  <addNormal ref="addNormal" @success="getList" :type="'个人生产经营所得(核定征收)'"></addNormal>
  <print-setting ref="printSetting" @success="printSettingA5"></print-setting>
</template>

<script>
import addNormal from './components/addNormal.vue'
import { getEaSalaryOnes,salayAutomaticValue,salarySheetInfo,salarySheetEdit,saveEaSalaryOneAll,deleteEaSalaryOne,salaryOneJmSave,salaryOneJmList } from '@/api/salary.js'
import { printSalary } from "@/api/printSet"
import { exportSalary } from "@/api/export"


export default {
  components: {
    addNormal
  },
  data() {
    return {
      dialogFormVisible2: false,
      jmseList:[],
      info:{},
      listData:[],
      options: [
        {
        value: '《财政部 税务总局关于实施小微企业和个体工商户所得税优惠政策的公告》 财政部 税务总局公告2021年第12号 ',
        label: '个体工商户减半征收经营所得个人所得税'
       },
      {
        value: '退役士兵从事个体经营减免个人所得税',
        label: '退役士兵从事个体经营减免个人所得税'
      },
      {
        value: '脱贫人口从事个体经营扣减个人所得税',
        label: '脱贫人口从事个体经营扣减个人所得税'
      },
      {
        value: '登记失业半年以上人员，零就业家庭、享受城市低保登记失业人员，毕业年度内高校毕业生从事个体经营扣减个人所得税',
        label: '登记失业半年以上人员，零就业家庭、享受城市低保登记失业人员，毕业年度内高校毕业生从事个体经营扣减个人所得税'
      },
      {
        value: '其他自然灾害受灾减免个人所得税',
        label: '其他自然灾害受灾减免个人所得税'
      },

      {
        value: '《中华人民共和国个人所得税法》 中华人民共和国主席令第九号第五条第一项',
        label: '残疾、孤老人员和烈属所得减征个人所得税'
      },

      {
        value: '《财政部 国家税务总局关于随军家属就业有关税收政策的通知》 财税〔2000〕84号第二条',
        label: '随军家属从事个体经营免征个人所得税'
      },
      {
        value: '《财政部 国家税务总局关于农村税费改革试点地区有关个人所得税问题的通知》 财税〔2004〕30号第一条',
        label: '取消农业税从事四业所得税暂免征收个人所得税'
      },
      {
        value: '《财政部 国家税务总局关于自主择业的军队转业干部有关税收政策问题的通知》 财税〔2003〕26号',
        label: '军转干部从事个体经营免征个人所得税'
      },
      {
        value: '其他',
        label: '其他'
      },
      ],
        options2: [{
        value: '生产经营所得',
        label: '生产经营所得'
      }],
      listQuery: {
        page: 1,
        limit: 20,
        sheetId: this.$store.getters["commons/params"].salaryPersonHdzsId
      },
      sels:[]
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(280)
    this.init()
  },
  methods: {
    deleteRow(index, rows) {
        rows.splice(index, 1);
    },
    jmseDialog(row){
      this.salaryOneInfo = row
      salaryOneJmList({salaryOneId:row.id}).then(res=>{
        if(res.data.data.list){
          this.jmseList = res.data.data.list
        }else{
          this.jmseList = []
        }
        this.dialogFormVisible2 = true
      })
    },
    // 新增
    handleAdd() {
      this.jmseList.push({
        jmAmount: 0,
        sdxm: "生产经营所得",
        jmsx: "",
        jmxz: "",
      });
    },
    changeJmsx(row){
      this.options.map(v=>{
        if(row.jmsx == v.label){
          row.jmxz = v.value
          row.jmsx = v.label
        }
      })
    },
    savejmse(){
      let param = {
        salaryOneId: this.salaryOneInfo.id,
        items: this.jmseList,
      }
      this.$confirm('如果减免税额为0，默认自动计算减免税额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        salaryOneJmSave(param).then(res=>{
          if(res.data.msg == "success"){
              this.init();
              this.dialogFormVisible2 = false
              this.computedYbtse()
          }
        })
      })
    },
    init() {
      this.getList()
      this.getSalarySheetInfo()
    },
    //添加
    addPersonal() {
      this.$refs.addNormal.openDialog()
    },
    // 获取取值信息
    getSalarySheetInfo() {
      salarySheetInfo({ id: this.$store.getters["commons/params"].salaryPersonHdzsId }).then(response => {
        this.info = response.data.data
      });
    },
    // 自动取值
    getValue(){
      salayAutomaticValue({
        type:'个人生产经营所得(核定征收)'
      }).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            type: "success",
            message: "取值成功"
          });
          this.getSalarySheetInfo({ id: this.$store.getters["commons/params"].salaryPersonHdzsId })
          this.computedYbtse()
        }
      });
    },
    // 保存取值信息
    saveValue(){
      this.info.srAmount = Number(this.info.srAmount)
      this.info.cbAmount = Number(this.info.cbAmount)
      this.info.mbyqndks = Number(this.info.mbyqndks)
      salarySheetEdit(this.info).then(res => {
        if(res.data.msg == "success") {
          this.getSalarySheetInfo({ id: this.$store.getters["commons/params"].salaryPersonHdzsId })
        }
      });
      this.computedYbtse()
    },
    del(e) {
      if(this.$checkSettleStatus()) return
      this.$confirm("确认删除该薪酬表", "提示", {
        confirmButtonText: " 确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass:'iconfont icon-shanchu'

      }).then(() => {
        deleteEaSalaryOne({ id: e.id }).then(res => {
          if(res.data.msg == "success") {
            this.getList();
            this.$message({
              type: "success",
              message: "删除成功"
            });
          }
          
        });
      })
    },
    //返回
    goback() {
      this.$router.push({
        path: "/input/remuneration/remuneration",
        name: "remuneration"
      });
    },
    //股东列表
    getList() {
      getEaSalaryOnes(this.listQuery).then(res => {
        if(res.data.data.list) {
          this.listData = res.data.data.list;
        }else{
          this.listData = []
        }
        
      })
    },
    handleSelectionChange(e) {
      this.sels = e
    },
    // 右上角保存
    saveAll() {
      this.listData.map(v=>{
        v.name = v.name
        v.costReduction = Number(v.costReduction)
        v.jzr = v.jzr
        v.deductSbPension = Number(v.deductSbPension)
        v.deductSbMedical = Number(v.deductSbMedical)
        v.deductSbUnemployment = Number(v.deductSbUnemployment)
        v.deductHouseFund = Number(v.deductHouseFund)
        v.syx = Number(v.syx)
        v.deductOther = Number(v.deductOther)
        v.jmse = v.jmse
        v.yjnse = Number(v.yjnse)
        v.ybtse = Number(v.ybtse)
      })
      saveEaSalaryOneAll(this.listData).then(res => {
        if(res.data.msg != "success"){
          this.$message.error(res.data.msg);
        }else{
          this.$message({
            message: "保存成功",
            type: "success"
          });
          // this.$router.go(-1);
          this.getList()
          this.$bus.emit('salarySheetUpdate')
        }
      });
    },
    printingA4(){
      let paramA4 = {
        paperType:'A4-H',
        bookNames : ['salray'],
        type:'salaryPerson_hd',
        comIds:[this.$store.getters['user/comInfo'].comId*1],
        period:this.$store.getters['user/comInfo'].period,
        query:this.listQuery
      }
      printSalary(paramA4).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    printingA5(){
      this.$refs.printSetting.dayinCustomStatus = true
    },
    printSettingA5(params){
      params.bookNames = ['salray']
      params.query = this.listQuery
      params.type = 'salaryPerson_hd'
      printSalary(params).then(res => {
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    GetInportListClick(){
      let arr = []
      this.sels.map(v=>{
        arr.push(v.employeeId)
      })
      let param = {
        period:this.$store.getters['user/comInfo'].period,
        comId:this.$store.getters['user/comInfo'].comId*1,
        type:'salaryPerson_hd',
        empId:arr
      }
      exportSalary(param).then(res=>{
        if(res.data.msg == "success"){
          window.open(res.data.data.url)
        }
      })
    },
    //计算
    computedYbtse(){
      let amount = Number(this.info.srAmount) * Number(this.info.sl) 
      this.listData.map(v=>{
        let tax = 0
        if(amount <= 30000){
          tax = amount*0.05
        }else if(amount>30000 && amount <= 90000){
          tax = (amount*0.1) - 1500
        }else if(amount>90000 && amount <= 300000){
          tax = (amount*0.2) - 10500
        }else if(amount>300000 && amount <= 500000){
          tax = (amount*0.3) - 40500
        }else{
          tax = (amount*0.35) - 65500
        }
        if(tax<0){
          tax = 0
        }
        v.ybtse = Number((tax - Number(v.yjnse) - Number(v.jmse)).toFixed(2))
        if(v.ybtse < 0){
          v.ybtse = 0
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.com_info{
  width: 100%;
  margin: 0 auto;
  margin-bottom: 5px;
  .top_info{
    width: 100%;
    margin-bottom: 10px;
    .top_item{
      display: inline-block;
      p{
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        line-height: 24px;
        margin-right: 20px;
        span{
          font-weight: normal;
        }
      }
    }
  }
  .bottom_info{
    width: 100%;
    margin: 0 auto;
    font-size: 14px;
    .info_list{
      p{
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #333;
        line-height: 24px;
        font-size: 14px;
      }
      span{
        margin-right: 14px;
        font-size: 14px;
      }
      .wxts{
        color:red;
        font-size: 14px;
        margin-top: 15px;
        font-size: 14px;
      }
    }
    
  }
}
.top_btns {
  .left_search {
    float: left;
    width: 30% !important;
  }
}
.addBtn{
  position: relative;
  top:-15px;
}
.top_right_btns {
  i {
    margin: 0 5px;
    cursor: pointer;
    font-size: 18px;
    line-height: 24px;
  }
  .iconfont icon-bianji{
    color: #f15a24; 
  }
  .iconfont icon-shanchu{
    color: #f56c6c; 
  }
}
.saveAll {
  position: fixed;
  bottom: 46px;
  left: 218px;
  opacity: 0.8;
  z-index: 2004;
}
table{
  table-layout: fixed;
}
.content{
  margin: 0 auto;
  span{
    line-height: 24px;
    font-size: 14px;
    color: #333;
  }
  tr{
    padding: 0;
    margin: 0;
    background: #fff;
  }
  input{
    width: 100%;
    line-height:24px;
    padding: 0 6px;
    font-size: 14px;
    text-align: right;
    padding-right: 12px;
    color: #333;
  }
}
.action_btns{
  text-align: center;
  i{
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;
  }
  .iconfont icon-bianji{
    color: #f15a24;
  }
  .iconfont icon-shanchu{
    color: #f56c6c;
  }
}
</style>
<style>
.el-tooltip__popper{ max-width:20%; }
.el-table td.action_btns{
  background: #eee !important;
}
</style>