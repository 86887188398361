<template>
  <div class="app-container">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="参数设置" name="first"></el-tab-pane>
      <el-tab-pane label="选项设置" name="second">
      </el-tab-pane>
    </el-tabs>
    <div class="content">
      <div v-if="activeName == 'first'" style="width: 1000px;">
        <el-form :model="form" label-width="100px">
          <el-form-item label="数量限制:">
            <el-switch
              v-model="form.customerStatus"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :active-value="'1'"
              :inactive-value="'2'"
              size="small"
            />
            <div class="flex">
              <div class="w120">每个成员最多持有</div>
              <el-input v-model="form.customerLimit" size="small" class="w50" clearable :disabled="form.value3"/>
              <div class="w300">个未成交客户</div>
            </div>
          </el-form-item> 
          <el-form-item label="释放规则:">
            <el-switch
              v-model="form.notFollowStatus"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :active-value="'1'"
              :inactive-value="'2'"
              size="small"
            />
            <div class="flex">
              <div class="w135">分配或领取后，超过</div>
              <el-input v-model="form.notFollowOverTime" size="small" class="w50" clearable :disabled="form.value4"/>
              <div class="w300">天未跟进自动释放</div>
            </div>
          </el-form-item> 
          <el-form-item>
            <el-switch
              v-model="form.notDealStatus"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :active-value="'1'"
              :inactive-value="'2'"
              size="small"
            />
            <div class="flex">
              <div class="w135">分配或领取后，超过</div>
              <el-input v-model="form.notDealOverTime"  size="small" class="w50" clearable :disabled="form.value5"/>
              <div class="w300">天未成交自动释放</div>
            </div>
          </el-form-item> 
          <el-form-item label="保护规则:">
            <el-switch
              v-model="form.protectStatus"
              inline-prompt
              active-text="开"
              inactive-text="关"
              :active-value="'1'"
              :inactive-value="'2'"
              size="small"
            />
            <div class="flex">
              <div class="w300">点击保护，释放日期增加</div>
              <el-input v-model="form.protectDays"  size="small" class="w50" clearable :disabled="form.value6"/>
              <div class="w135">天，最多可同时保护</div>
              <el-input v-model="form.protectCustomers"  size="small" class="w50" clearable :disabled="form.value6"/>
              <div class="w300">个客户</div>
            </div>
            <div class="save">
              <el-button type="primary" size="small" style="float: right;" @click="saveSetting">保存</el-button>
            </div>
          </el-form-item> 
        </el-form>
      </div>
      <div v-else>
        <el-tabs tab-position="left" v-model="activeNameOptions" class="demo-tabs" @tab-click="handleClickOption">
          <el-tab-pane label="线索分类" name="clue">
            <div class="xxStyle" v-for="(item,i) in clueTypeList" v-if="clueTypeList.length > 0">
              <div style="width: 30px;">{{ i + 1 }}</div>
              <div style="width: 240px;">
                <el-input v-model="item.name" placeholder="" size="small" style="width: 100%;" clearable />
              </div>
              <div style="margin: 3px 0 0 20px;">
                <el-icon @click="editClue('add')" style="cursor: pointer;"><Plus /></el-icon>
                <el-icon style="margin-left: 6px; cursor: pointer;" @click="editClue('del',i)" ><Minus /></el-icon>
              </div>
            </div>
            <div v-else class="empty">
              <el-empty>
                <el-button type="primary" size="small" @click="editClue('add')">新增</el-button>
              </el-empty>
            </div>
            <div class="save" v-if="clueTypeList.length > 0">
              <el-button type="primary" size="small" style="float: right;" @click="saveSetting">保存</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="跟进阶段" name="follow">
            <div class="xxStyle" v-for="(item,i) in followStatusList" v-if="followStatusList.length > 0">
              <div style="width: 30px;">{{ i + 1 }}</div>
              <div style="width: 240px;">
                <el-input v-model="item.name" placeholder="" size="small" style="width: 100%;" clearable />
              </div>
              <div style="margin: 3px 0 0 20px;">
                <el-icon @click="editFollow('add')" style="cursor: pointer;"><Plus /></el-icon>
                <el-icon style="margin-left: 6px;cursor: pointer;" @click="editFollow('del',i)"><Minus /></el-icon>
              </div>
            </div>
            <div v-else class="empty">
              <el-empty>
                <el-button type="primary" size="small" @click="editFollow('add')">新增</el-button>
              </el-empty>
            </div>
            <div class="save" v-if="followStatusList.length > 0">
              <el-button type="primary" size="small" style="float: right;" @click="saveSetting">保存</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getFollowStageList,clueLevel,settings,optionSetting,getSettings } from "@/api/newCrm.js";

export default {
  name: '',
  components: {
  },
  props: {},
  data () {
    return {
      activeName:'first',
      activeNameOptions:'clue',
      clueTypeList:[],
      followStatusList: [],
      form: {},
      checkList: [],
      checkList1: [],
      checkList2: [],
    }
  },
  computed: {},
  watch: {},
  created () {
    this.contentStyleObj = this.$getHeight(318);
    this.getSetting()
    this.getOptionsFollowList()
    this.getOptionsLevelList()
  },
  mounted () {},
  methods: {
    handleClick(val){
      this.activeName = val.props.name;
      if(val.props.name == 'first'){
        this.getSetting()
      }
    },
    getSetting(){
      getSettings().then(res => {
          console.log(res);
          if (res.data.msg == 'success') {
            this.form = res.data.data.data
          }
        })
    },

    handleClickOption(val){
      this.activeNameOptions = val.props.name;
    },

    getOptionsFollowList(){
      getFollowStageList().then(res => {
        console.log(res);
        if (res.data.msg == 'success') {
          let tempList = res.data.data.data
          this.followStatusList = []
          tempList.forEach(item => {
            this.followStatusList.push({name: item.value})
          })
        }
      })
    },
    getOptionsLevelList(){
      clueLevel().then(res => {
        console.log(res);
        if (res.data.msg == 'success') {
          let tempList = res.data.data.data
          this.clueTypeList = []
          tempList.forEach(item => {
            this.clueTypeList.push({name: item.value})
          })
        }
      })
    },

    editClue(type,i){
      if(type == 'add'){
        this.clueTypeList.push({
          name:'',
        })
      }else{
        this.clueTypeList.splice(i,1)
      }
    },

    editFollow(type,i){
      if(type == 'add'){
        this.followStatusList.push({
          name:'',
        })
      }else{
        this.followStatusList.splice(i,1)
        // this.followStatusList[i].name = ''
      }
    },
    saveSetting(){
      if(this.activeName == 'first'){
        optionSetting(this.form).then(res => {
          console.log(res);
          if (res.data.msg == 'success') {
            this.$qzfMessage("保存成功")
          }
        })
      }else{
        let pamars = []
        pamars.push({type:'clueLevel',list:this.clueTypeList},{type:'followStages',list:this.followStatusList})
        settings(pamars).then(res => {
          console.log(res);
          if (res.data.msg == 'success') {
            this.$qzfMessage("保存成功")
          }
        })
      }
    }
  }
}
</script>


<style scoped lang="scss">
.content{
  width: 100%;
  height: 100%;
}
.xxStyle{
  display: flex;
  height: 40px;
  align-items: center;
  margin: 0 40px;
}
.w300{
  width: 160px;
}
.w135{
  width: 135px;
}
.w120{
  width: 120px;
}
.w50{
  width: 50px;
  margin-right: 10px;
}
.flex{
  display: flex;
  margin-left: 20px;
}
.save{
  margin-top: 80px;
  width: 700px;
}
.empty{
  width: 600px;
  height: 400px;
}
</style>
